import React from 'react';

const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4 3.70006C9.84183 3.36869 10.4686 3.45823 10.8 3.90006L16.8 11.9001C17.0667 12.2556 17.0667 12.7445 16.8 13.1001L10.8 21.1001C10.4686 21.5419 9.84183 21.6314 9.4 21.3001C8.95817 20.9687 8.86863 20.3419 9.2 19.9001L14.75 12.5001L9.2 5.10006C8.86863 4.65823 8.95817 4.03143 9.4 3.70006Z"
      />
    </svg>
  );
};

export default ChevronRight;
